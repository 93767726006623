













import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { vxm } from '@/store'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  headers = []
  routes = {}
  rowActions = []
  topActions = []

  mounted() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Name',
        value: 'name',
        filter: { focus: true },
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.rowActions = [
      {
        id: 'edit',
        route: (item) => {
          return {
            name: 'AutoBooking/Edit',
            params: { id: item.id },
          }
        },
      },
      {
        id: 'copy',
        click: async(_parent, item) => {
          await this.$axios
            .post('/v4/site/calendars/0/auto-bookings/' + item.id + '/copy')
            .then((response) => {
              this.$router.push({
                name: 'AutoBooking/Edit',
                params: { id: response.data.data.id },
              })
            })
            .catch((err) => {
              vxm.alert.onAxiosError(err, 'Could not copy auto-booking')
            })
        },
      },
      {
        id: 'delete',
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return {
            name: 'AutoBooking/New',
            params: { id: '0' },
          }
        },
      },
    ]
  }

  get url() {
    return '/v4/site/calendars/0/auto-bookings'
  }
}
